@charset "utf-8";

@import "variables";



#slides-wrap{
	position: relative;
	padding: 10px;
	.slides {
		overflow: hidden;
		position: relative;
		background-color: rgba(0,0,0,.05);
		div{
			line-height: 0;
			overflow: hidden;
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			span{
				display: block;
				position: relative;
				img,
				svg{
					transition: all 8s linear;
				}
				svg{
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: auto;
				}
			}
		}
		div:nth-child(odd) {
			span{
				img,
				svg{
					transform: scale(1.2);
				}
			}
		}
		div:nth-child(odd).show {
			z-index: 1;
			position: relative;
			span{
				img,
				svg{
					transform: scale(1);
				}
			}
		}
		div:nth-child(even) {
			span{
				img,
				svg{
					transform: scale(1);
				}
			}
		}
		div:nth-child(even).show {
			z-index: 1;
			position: relative;
			span{
				img,
				svg{
					transform: scale(1.2);
				}
			}
		}
		div.show{
			span{
				img{
					opacity: 0;
					animation: IMAGE_SHOWING 5s linear 0s forwards; // or 3s
				}
				svg{
					path{
						stroke:rgba(0,0,0,.8);
					    fill:transparent;
					    stroke-width:1;    
					    stroke-dasharray: 3000;
					    stroke-dashoffset:3000;
						animation: DRAWING 5s linear 0s forwards; // or 3s
					}
				}
			}
		}
	}
	.catch{
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 40%;
		max-width: 300px;
		height: auto;
		z-index: 1;
		transform: translate(-50%, -50%);
	}
}

.sec{
	position: relative;
	padding: 12% 0 0;
	.sec-head{
		text-align: center;
		padding: 0 0 30px;
		h2{

		}
		p{
			margin: 10px 0 0;
			font-family: $fontGothic;
		}
	}
}
.sec.below{
	padding: 12% 0;
}

.sec01{
	.sec-body{
		.posts{
			.post{
				padding: 3% 0;
				.post-in{
					position: relative;
					.post-date{
						color: #7d7d7d;
					}
					.post-title{
						text-decoration: underline;
					}
					p{
						color: #333;
						font-size: 13px;
						margin: 5px 0 0;
					}
					.post-tag{
						color: $baseColor;
						margin: 5px 0 0;
					}
				}
				.post-in:hover{
					.post-title{
						text-decoration: none;
					}
				}
			}
			.post:first-of-type{
				padding: 0 0 3%;
			}
		}
	}
	.bg-air{
		position: absolute;
		top: 0;
		right: 0;
		z-index: 0;
		width: 40%;
		max-width: 290px;
	}
}

.sec02{
	.sec-body{
		.inner{
			padding: 0;
		}
		ul#slick-blog{
			li{
				padding: 0 10px;
				.li-in{
					overflow: hidden;
					position: relative;
					.li-img{
						span{
							display: block;
							overflow: hidden;
							position: relative;
							line-height: 0;
							width: 100%;
							height: 0;
							padding-top: 70%;
							img{
								position: absolute;
								top: 50%;
								left: 50%;
								width: 120%;
								height: auto;
								transform: translate(-50%, -50%);
								transition: transform .3s;
							}
						}
					}
					.li-meta{
						margin: 10px 0 0;
						.li-date{
							color: #7d7d7d;
						}
					}
				}
				.li-in:hover{
					.li-img{
						span{
							img{
								transform: translate(-50%, -50%) scale(1.2);
							}
						}
					}
				}
			}
		}
	}
}


.sec03{
	.sec-body{
		letter-spacing: -.4em;
		.sec-left,
		.sec-right{
			letter-spacing: normal;
		}
		.sec-right{
			padding: 0 15px;
			.slogan,
			.idea{
				text-align: center;
				p{
					line-height: 2.4;
					span{
						display: inline-block;
					}
				}
			}
			.slogan{
				h3{
					font-size: 18px;
					margin: 30px 0 20px;
					span{
						display: block;
					}
				}
				p{
					strong{
						font-size: 18px;
					}
				}
			}
			.idea{
				margin: 30px 0 0;
				p{
					strong{
						display: inline-block;
						font-size: 16px;
						b{
							font-size: 18px;
							font-weight: bold;
						}
					}
				}
			}
		}
	}
}

.sec04{

}
#shop{
	margin: 0 -10px;
	.grid-sizer,
	.item{
		width: 50%;
		padding: 5px;
	}
	.item.wide{
		width: 100%;
	}
	.item{
		.item-in{
			line-height: 0;
			overflow: hidden;
			position: relative;
			display: block;
			width: 100%;
			height: 0;
			padding-top: 65%;
			img{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 110%;
				height: auto;
				transform: translate(-50%, -50%);
				transition: all 2s;
			}
			
		}
	}
	.item.wide{
		.item-in{
			padding-top: 49%;
		}
	}
	.item.txt{
		text-align: center;
		.item-in{
			line-height: $lineHeight;
			height: auto;
			padding: 5% 0;
			p{
				span{
					display: inline-block;
				}
			}
		}
	}
	.item.port{
		.item-in{
			padding-top: calc(130% + 9.5px); // padding*2 - 0.5
		}
	}
}

.sec05{
	.sec-right{
		text-align: center;
		h3{
			span{
				font-size: 16px;
			}
			strong{
				display: inline-block;
				position: relative;
				color: $baseColor;
				font-size: 18px;
				padding: 15px 30px;
				margin: 10px 0;
			}
			strong:before,
			strong:after{
				content: '';
				display: block;
				position: absolute;
				width: 15px;
				height: 15px;
			}
			strong:before{
				top: 0;
				left: 0;
				border-top: 1px solid $textColor;
				border-left: 1px solid $textColor;
			}
			strong:after{
				bottom: 0;
				right: 0;
				border-right: 1px solid $textColor;
				border-bottom: 1px solid $textColor;
			}
		}
	}
	.sec-left{
		margin: 50px 0 0;
		ul{
			letter-spacing: -.4em;
			li{
				letter-spacing: normal;
				margin: 0 0 30px;
				.li-in{
					.li-head{
						width: 60%;
						margin: auto;
						.li-prof{
							text-align: right;
							position: relative;
							z-index: 1;
							font-size: 18px;
							font-style: italic;
							margin: 0 0 5px;
							transform: translateX(15px) rotate(15deg);
						}
					}
					.li-body{
						margin: 15px 0 0;
						h4{
							text-align: center;
							line-height: 1.6;
							strong{
								display: block;
								font-size: 18px;
							}
						}
						p{
							margin: 10px 0 0;
							color: #666;
							font-size: 13px;
							font-family: $fontGothic;
						}
					}
				}
			}
		}
	}
	.sec-foot{
		margin: 50px 0 0;
	}
}


.sec06{
	.sec-body{
		.menu-info{
			text-align: center;
			margin: 0 0 30px;
			strong{
				font-size: 16px;
			}
			table{
				width: auto;
				margin: auto;
				tr{
					td{
						text-align: left;
						padding: 0 10px;
					}
					td:last-of-type{
						font-family: $fontGothic;
						text-align: right;
					}
				}
			}
			p{
				margin: 5px 0;
			}
		}
	}
}
nav#menus-nav{
	margin: 0 0 40px;
	ul{
		letter-spacing: -.4em;
		margin: 0 -1%;
		li{
			letter-spacing: normal;
			cursor: pointer;
			display: inline-block;
			vertical-align: top;
			text-align: center;
			width: 48%;
			margin: 1%;
			font-size: 13px;
			padding: 15px 5px;
			background-color: #eee;
			transition: all .3s;
		}
		li.selected,
		li:hover{
			color: #fff;
			background-color: $baseColor;
		}
	}
}
#menus-body{
	overflow: hidden;
	ul{
		position: relative;
		li{
			float: left;
			.inner{

			}
			.menu-wrap{
				margin: 30px 0 0;
				.menu-cat{
					padding: 0 0 10px;
					margin: 0 0 10px;
					border-bottom: 3px solid rgba(0,0,0,.1);
					h3{
						color: $baseColor;
						font-size: 18px;
						small{
							margin-left: 5px;
							color: #7d7d7d;
							font-size: $fontSize;
							font-style: italic;
						}
						small:before{
							content: '－ ';
						}
					}
					h3:before{
						content: '◆ ';
					}
				}
				.menu-dtl{
					table{
						width: 100%;
						tr{
							
							th,
							td{
								padding: 10px 2.5%;
							}
							th{
								text-align: left;
								background-color: rgba(0,0,0,.1);
							}
							th:before{
								content: '■ ';
							}
							th + td{
								background-color: rgba(0,0,0,.1);
							}
							td{
								font-family: $fontGothic;
								border-bottom: 1px dotted rgba(0,0,0,.1);
								span{
									display: inline-block;
								}
							}
							td:last-of-type{
								// white-space: nowrap;
								min-width: 100px;
								text-align: right;
							}
						}
						tr:last-of-type{
							th,
							td{
								border: 0;
							}
						}
					}
					table:not(:first-of-type),
					table.top-mgn{
						margin: 20px 0 0;
					}
				}
			}
			.notes{
				text-align: center;
				background-color: rgba(255,0,0,.1);
				padding: 5%;
				border-radius: 10px;
				h4{
					color: #aa003e;
					font-size: 16px;
					margin: 0 0 10px;
				}
				.notes-dtl{
					p{
						margin: 0 0 10px;
					}
					table{
						width: auto;
						margin: auto;
						tr{
							td{
								padding: 5px 10px;
							}
							td:last-of-type{
								text-align: right;
								font-family: $fontGothic;
							}
						}
					}
				}
			}
		}
		li:first-of-type{
			background: url(../img/top/sec06/bg01.jpg) no-repeat center;
			background-size: cover;
		}
		li:nth-of-type(2){
			background: url(../img/top/sec06/bg02.jpg) no-repeat center;
			background-size: cover;
		}
		li:nth-of-type(3){
			background: url(../img/top/sec06/bg03.jpg) no-repeat center;
			background-size: cover;
		}
		li:nth-of-type(4){
			background: url(../img/top/sec06/bg04.jpg) no-repeat center;
			background-size: cover;
		}
	}
}


.sec07{
	color: #fff;
	background-attachment: fixed;
	background-image: url(../img/top/sec07/bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	.sec-body{
		.job{
			margin: 20px 0 0;
			.job-head{
				font-size: 24px;
				border-bottom: 1px solid rgba(255,255,255,.3);
				small{
					font-size: $fontSize;
				}
			}
			.job-body{
				font-family: $fontGothic;
				line-height: 1.8;
				table{
					tr{
						display: block;
						margin: 15px 0 0;
						th,
						td{
							display: block;
							text-align: left;
						}
						th{
							// font-weight: bold;
						}
						td{
							margin: 0 0 0 1.4em;
						}
					}
				}
			}
		}
		.job:first-of-type{
			margin: 0;
		}
	}
	.sec-foot{
		margin: 50px 0 0;
		.sec-foot-in{
			color: $baseColor;
			background-color: rgba(255,255,255,.7);
			padding: 5%;
			h3{
				text-align: center;
				font-size: 16px;
				span{
					display: inline-block;
				}
			}
			table{
				tr{
					display: block;
					margin: 15px 0 0;
					th,
					td{
						display: block;
						text-align: left;
					}
					th{
						// font-weight: bold;
					}
					td{
						margin: 0 0 0 1.4em;
						a{
							display: inline-block;
							font-size: 18px;
						}
						span{
							display: inline-block;
						}
					}
				}
			}
		}
	}
}
.sec07:before{
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background:linear-gradient(to right, rgba(41,102,162,.8), rgba(41,102,162,1) 100%);
}

.sec08{
	background-color: #eee;
	.sec-body{
		.nikai{
			position: relative;
			text-align: center;
			color: #fff;
			padding: 5%;
			background: url(../img/top/sec08/bg.jpg) no-repeat center;
			background-size: cover;
			.nikai-in{
				position: relative;
				z-index: 1;
				.nikai-img{
					margin: 10px 0;
					img{
						width: 190px;
					}
				}
				.nikai-txt{
					// font-size: 16px;
					font-family: $fontGothic;
				}
			}
			a.permalink{
				z-index: 2;
			}
		}
		.nikai:before{
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,.6);
			transition: background-color .5s;
		}
		.nikai:hover{
			.nikai-in{
				.nikai-img{
					img{
						animation: rotateLoop 1.5s ease-in-out 0s infinite;
					}
				}
			}
		}
		.nikai:hover:before{
			background-color: rgba(0,0,0,.4);
		}
	}
}

@keyframes rotateLoop {
	0%{transform: rotateY(0deg);}
	100%{transform: rotateY(360deg)}
}

.sec09{
	.sec-body{
		font-family: $fontGothic;
		line-height: 1.8;
		table{
			margin: 20px 0 0;
			tr{
				display: block;
				margin: 15px 0 0;
				th,
				td{
					display: block;
					text-align: left;
				}
				th{
					// font-weight: bold;
				}
				td{
					margin: 0 0 0 1.4em;
				}
			}
		}
	}
}
ul#slick-app{
	li{
		padding: 0 5px;
		img{
			width: auto;
			height: 200px;
		}
	}
}

/* google map */
#gmap {
	position: relative;
	padding-bottom: 80%;
	height: 0;
	overflow: hidden;
}

#gmap-in {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}


#_adjuvant{
	.sec-head{
		h2{
			font-size: 20px;
		}
		p{
			margin: 0;
		}
		p.lead{
			color: $baseColor;
			font-size: 15px;
			font-family: $fontBase;
			span{
				display: inline-block;
			}
		}
	}
	.sec-body{
		letter-spacing: -.4em;
		.sec-left,
		.sec-right{
			letter-spacing: normal;
		}
		.sec-txt{
			padding: 5% 15px;
			ul,
			ol{
				li{
					position: relative;
					padding: 3px 0 3px 1.2em;
				}
			}
			ol{
				li{
					span{
						position: absolute;
						top: 3px;
						left: 0;
					}
				}
			}
			ul{
				li:before{
					content: '';
					display: block;
					position: absolute;
					top: 11px;
					left: 0;
					width: 10px;
					height: 10px;
					background-color: #000;
					border-radius: 50%;
				}
			}
		}
	}
}




@media screen and (min-width: 600px) {
	#slides-wrap{
		padding: 15px;
	}

	.sec{
		padding: 10% 0 0;
		.sec-head{
			padding: 0 0 40px;
		}
	}
	.sec.below{
		padding: 10% 0;
	}

	.sec03{
		.sec-body{
			.sec-right{
				.slogan{
					h3{
						margin: 50px 0 40px;
						span{
							display: inline-block;
						}
					}
				}
			}
		}
	}

	.sec04{

	}
	#shop{
		.grid-sizer,
		.item{
			width: 33.33%;
		}
		.item.txt{
			.item-in{
				p{
					font-size: 16px;
				}
			}
		}
	}

	.sec05{
		.sec-right{
			h3{
				span{
					font-size: 18px;
				}
				strong{
					font-size: 20px;
				}
			}
		}
		.sec-left{
			ul{ 
				li{
					display: inline-block;
					vertical-align: top;
					width: 50%;
					.li-in{
						padding: 0 15px;
					}
				}
			}
		}
	}

	.sec06{
	
	}
	nav#menus-nav{
		ul{
			li{
				font-size: 14px;
			}
		}
	}
	#menus-body{
		ul{
			li{
				.menu-wrap{
					.menu-cat{
						h3{
							font-size: 20px;
						}
					}
					.menu-dtl{
						table{
							tr{
								th{
									font-size: 16px;
								}
							}
						}
					}
				}
				.notes{
					h4{
						font-size: 18px;
					}
				}
			}
		}
	}

	.sec07{
		.sec-body{
			.job{
				.job-head{
					font-size: 26px;
				}
				.job-body{
					margin: 10px 0 0;
					table{
						tr{
							display: table-row;
							margin: 0;
							th,
							td{
								display: table-cell;
								margin: 0;
							}
							th{
								white-space: nowrap;
								padding: 5px 5px 5px 0;
							}
							td{
								padding: 5px 0 5px 5px;
							}
						}
					}
				}
			}
		}
		.sec-foot{
			.sec-foot-in{
				h3{
					font-size: 18px;
				}
				table{
					max-width: 600px;
					margin: 15px auto 0;
					tr{
						display: table-row;
						margin: 0;
						th,
						td{
							display: table-cell;
							margin: 0;
						}
						th{
							white-space: nowrap;
							padding: 5px 5px 5px 0;
						}
						td{
							padding: 5px 0 5px 5px;
							a{
								font-size: 20px;
							}
						}
					}
				}
			}
		}
	}

	.sec08{
		.sec-body{
			.nikai{
				.nikai-in{
					.nikai-txt{
						font-size: 18px;
					}
				}
			}
		}
	}

	#gmap {
		padding-bottom: 40%;
	}

	#_adjuvant{
		.sec-head{
			h2{
				font-size: 22px;
			}
			p.lead{
				font-size: 16px;
			}
		}
	}

}


@media screen and (min-width: 800px) {
	#slides-wrap{
		.catch{
			width: 30%;
		}
	}

	.sec{
		padding: 8% 0 0;
		.sec-head{
			padding: 0 0 50px;
		}
	}
	.sec.below{
		padding: 8% 0;
	}

	.sec01{
		.sec-body{
			.posts{
				.post{
					.post-in{
						padding-left: 30px;
					}
					.post-in:before{
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 5px;
						height: 100%;
						background-color: rgba(0,0,0,.05);
					}
					.post-in:after{
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: 0;
						width: 5px;
						height: 0;
						background-color: #000;
						transition: height .3s;
					}
					.post-in:hover:after{
						height: 100%;
					}
				}
			}
		}
	}

	.sec02{
		.sec-body{
			.inner{
				padding: 0 10px;
			}
			ul#slick-blog{
				li{
					padding: 0 15px;
				}
			}
		}
	}

	.sec03{
		.sec-body{
			.sec-left,
			.sec-right{
				display: inline-block;
				vertical-align: top;
				width: 50%;
			}
			.sec-right{
				margin: 0;
				padding: 0 15px 0 30px;
				.slogan,
				.idea{
					text-align: left;
				}
				.slogan{
					h3{
						margin: 0;
					}
				}
				.idea{
					margin: 20px 0 0;
				}
			}
		}
	}

	.sec04{

	}
	#shop{
		.grid-sizer,
		.item{
			padding: 7.5px;
		}
		.item.port{
			.item-in{
				padding-top: calc(130% + 14.5px); // padding*2 - 0.5
			}
		}
	}

	.sec05{
		.sec-right{
			float: right;
			width: 200px;
			h3{
				span{
					writing-mode: vertical-rl;
					-ms-writing-mode: tb-rl;
					-webkit-writing-mode:vertical-rl;
					letter-spacing: 5px;
				}
			}
		}
		.sec-left{
			margin: 0 200px 0 0;
			ul{ 
				li{
					width: 33.3%;
					.li-in{
						.li-head{
							width: 100%;
						}
						.li-body{
							h4{
								text-align: left;
							}
						}
					}
				}
			}
		}
	}

	.sec06{
		.sec-body{
			.menu-info{
				margin: 0 0 50px;
				strong{
					font-size: 18px;
				}
			}
		}
	}
	nav#menus-nav{
		margin: 0;
		border-bottom: 3px solid $baseColor;
		ul{
			margin: 0 -0.5%;
			text-align: center;
			li{
				vertical-align: bottom;
				width: 24%;
				margin: 0 0.5%;
				border-bottom: 0;
			}
			li.selected,
			li:hover{
				padding: 20px 5px;
			}
		}
	}

	.sec07{
		.sec-body{
			.job{
				overflow: hidden;
				margin: 50px 0 0;
				.job-head{
					float: left;
					width: 60px;
					border: 0;
					font-size: 28px;
				}
				.job-body{
					margin: 0 0 0 80px;
					padding: 0 0 0 20px;
					border-left: 1px solid rgba(255,255,255,.3);
					table{
						tr{
							display: table-row;
							margin: 0;
							th,
							td{
								display: table-cell;
								margin: 0;
							}
							th{
								white-space: nowrap;
								padding: 5px 5px 5px 0;
							}
							td{
								padding: 5px 0 5px 5px;
							}
						}
					}
				}
			}
		}
		.sec-foot{
			.sec-foot-in{
				h3{
					font-size: 20px;
				}
				table{
					tr{
						td{
							a{
								font-size: 22px;
							}
						}
					}
				}
			}
		}
	}

	.sec08{
		.sec-body{
			.nikai{
				.nikai-in{
					width: 90%;
					margin: auto;
					.nikai-img,
					.nikai-txt{
						display: inline-block;
						vertical-align: middle;
					}
					.nikai-img{
						width: 190px;
					}
					.nikai-txt{
						width: calc(100% - 190px);
						text-align: left;
						font-size: 20px;
						padding-left: 30px;
					}
				}
			}
		}
	}

	.sec09{
		.sec-body{
			overflow: hidden;
			position: relative;
			font-family: $fontGothic;
			line-height: 1.8;
			table{
				width: 47%;
				float: right;
				margin: 20px 0;
				tr{
					display: table-row;
					margin: 0;
					th,
					td{
						display: table-cell;
						margin: 0;
					}
					th{
						white-space: nowrap;
						padding: 5px 5px 5px 0;
					}
					td{
						padding: 5px 0 5px 5px;
					}
				}
			}
		}
	}
	ul#slick-app{
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: auto;
		li{
			img{
				height: 260px
			}
		}
	}


	#_adjuvant{
		.sec-head{
			h2{
				font-size: 24px;
			}
			p.lead{
				font-size: 17px;
			}
		}
		.sec-body{
			position: relative;
			.sec-txt{
				font-size: 15px;
			}
		}
		.sec-body.sb01{
			text-align: right;
			.sec-left{
				overflow: hidden;
				position: absolute;
				top: 0;
				left: 0;
				width: 50%;
				height: 100%;
				span{
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						width: auto;
						height: 110%;
						transform: translate(-50%, -50%);
					}
				}
			}
			.sec-right{
				display: inline-block;
				text-align: left;
				width: 50%;
				padding: 5%;
			}
		}
		.sec-body.sb02{
			.sec-right{
				overflow: hidden;
				position: absolute;
				top: 0;
				right: 0;
				width: 50%;
				height: 100%;
				span{
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						width: auto;
						height: 110%;
						transform: translate(-50%, -50%);
					}
				}
			}
			.sec-left{
				display: inline-block;
				text-align: left;
				width: 50%;
				padding: 5%;
				ul{
					li{
						padding: 5px 0 5px 1.2em;
					}
					li:before{
						top: 14px;
					}
				}
			}
		}
	}

	

}


@media screen and (min-width: 1000px) {
	.sec{
		padding: 6% 0 0;
	}
	.sec.below{
		padding: 6% 0;
	}

	.sec03{
		.sec-body{
			.sec-right{
				padding: 0 15px 0 40px;
				.slogan{
					h3{
						font-size: 20px;
					}
				}
			}
		}
	}

	.sec04{

	}
	#shop{
		.grid-sizer,
		.item{
			width: 25%;
		}
		.item.wide{
			width: 50%;
		}
		.item.txt{
			text-align: right;
			.item-in{
				p{
					padding-right: 15px;	
				}
			}
		}
	}

	.sec05{
		.sec-right{
			width: 240px;
			h3{
				span{
					font-size: 20px;
				}
				strong{
					font-size: 22px;
				}
			}
		}
		.sec-left{
			margin: 0 240px 0 0;
		}
	}

	.sec06{
	
	}
	nav#menus-nav{
		ul{
			li{
				font-size: 15px;
			}
		}
	}
	#menus-body{
		ul{
			li{
				.menu-wrap:first-of-type{
					margin: 50px 0 0;
				}
			}
		}
	}


	.sec08{
		.sec-body{
			.nikai{
				.nikai-in{
					.nikai-txt{
						font-size: 22px;
						padding-left: 40px;
					}
				}
			}
		}
	}

	#_adjuvant{
		.sec-head{
			h2{
				font-size: 26px;
			}
			p.lead{
				font-size: 18px;
			}
		}
	}


}


@media screen and (min-width: 1200px) {
	.sec{
		padding: 100px 0 0;
	}
	.sec.below{
		padding: 100px 0;
	}
	
	.sec01{
		.bg-air{
			right: auto;
			left: 50%;
			transform: translateX(100%);
		}
	}

	.sec02{
		.sec-body{
			.inner{
				padding: 0;
			}
			ul#slick-blog{
				margin: 0 -25px;
				li{
					padding: 0 25px;
				}
			}
		}
	}

	.sec03{
		.sec-body{
			.sec-right{
				padding: 0 15px 0 50px;
				.slogan{
					h3{
						font-size: 22px;
					}
				}
			}
		}
	}

	.sec04{

	}
	#shop{
		.grid-sizer,
		.item{
			width: 16.66%;
			padding: 10px;
		}
		.item.port{
			.item-in{
				padding-top: calc(130% + 19.5px); // padding*2 - 0.5
			}
		}
	}
	
	.sec05{
		.sec-right{
			width: 260px;
			h3{
				span{
					font-size: 22px;
				}
				strong{
					font-size: 24px;
				}
			}
		}
		.sec-left{
			margin: 0 260px 0 0;
			ul{ 
				li{
					width: 25%;
				}
			}
		}
	}

	#_adjuvant{
		.sec-head{
			h2{
				font-size: 28px;
			}
			p.lead{
				font-size: 20px;
			}
		}
	}


}



@keyframes DRAWING{
	0%{stroke-dashoffset:3000;stroke:rgba(0,0,0,.8);}
	50%{stroke-dashoffset:0;stroke-width:1;stroke:transparent;}
	100%{stroke-dashoffset:0;stroke-width:0;stroke:transparent;}
}

@keyframes IMAGE_SHOWING{
	0%{opacity: 0;}
	10%{opacity: 0;}
	50%{opacity: 1;}
	100%{opacity: 1;}
}

// bubble
@for $i from 0 through 50 {
    $time: $i*0.2;
    $scale: $i*0.1;
    $b_ease: cubic-bezier(0.47, 0, 0.745, 0.715);
 
    .shake#{$i * 1} {
        animation: shake ($time+2)+s ease 0s infinite normal;
        -webkit-animation: shake ($time+2)+s ease 0s infinite normal;
    }
    .move#{$i * 1}  {
        animation: move ($time+5)+s $b_ease $time+s infinite normal;
        -webkit-animation: move ($time+5)+s $b_ease $time+s infinite normal;
 
        position: absolute;
        bottom: -10%;
    }
    .pos#{$i * 1}  {
        left: percentage($i*2/100);
    }
    .scale#{$i * 1}  {
        transform: scale($scale);
        -webkit-transform: scale($scale);
    }
}

@keyframes shake {
  0% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(10px);
  }
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(-1500px);
    opacity: 0;
  }
}