@charset "UTF-8";
/* font-family */
/* easing */
#slides-wrap {
  position: relative;
  padding: 10px;
}

#slides-wrap .slides {
  overflow: hidden;
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
}

#slides-wrap .slides div {
  line-height: 0;
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

#slides-wrap .slides div span {
  display: block;
  position: relative;
}

#slides-wrap .slides div span img,
#slides-wrap .slides div span svg {
  transition: all 8s linear;
}

#slides-wrap .slides div span svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

#slides-wrap .slides div:nth-child(odd) span img,
#slides-wrap .slides div:nth-child(odd) span svg {
  transform: scale(1.2);
}

#slides-wrap .slides div:nth-child(odd).show {
  z-index: 1;
  position: relative;
}

#slides-wrap .slides div:nth-child(odd).show span img,
#slides-wrap .slides div:nth-child(odd).show span svg {
  transform: scale(1);
}

#slides-wrap .slides div:nth-child(even) span img,
#slides-wrap .slides div:nth-child(even) span svg {
  transform: scale(1);
}

#slides-wrap .slides div:nth-child(even).show {
  z-index: 1;
  position: relative;
}

#slides-wrap .slides div:nth-child(even).show span img,
#slides-wrap .slides div:nth-child(even).show span svg {
  transform: scale(1.2);
}

#slides-wrap .slides div.show span img {
  opacity: 0;
  animation: IMAGE_SHOWING 5s linear 0s forwards;
}

#slides-wrap .slides div.show span svg path {
  stroke: rgba(0, 0, 0, 0.8);
  fill: transparent;
  stroke-width: 1;
  stroke-dasharray: 3000;
  stroke-dashoffset: 3000;
  animation: DRAWING 5s linear 0s forwards;
}

#slides-wrap .catch {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40%;
  max-width: 300px;
  height: auto;
  z-index: 1;
  transform: translate(-50%, -50%);
}

.sec {
  position: relative;
  padding: 12% 0 0;
}

.sec .sec-head {
  text-align: center;
  padding: 0 0 30px;
}

.sec .sec-head p {
  margin: 10px 0 0;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

.sec.below {
  padding: 12% 0;
}

.sec01 .sec-body .posts .post {
  padding: 3% 0;
}

.sec01 .sec-body .posts .post .post-in {
  position: relative;
}

.sec01 .sec-body .posts .post .post-in .post-date {
  color: #7d7d7d;
}

.sec01 .sec-body .posts .post .post-in .post-title {
  text-decoration: underline;
}

.sec01 .sec-body .posts .post .post-in p {
  color: #333;
  font-size: 13px;
  margin: 5px 0 0;
}

.sec01 .sec-body .posts .post .post-in .post-tag {
  color: #2966A2;
  margin: 5px 0 0;
}

.sec01 .sec-body .posts .post .post-in:hover .post-title {
  text-decoration: none;
}

.sec01 .sec-body .posts .post:first-of-type {
  padding: 0 0 3%;
}

.sec01 .bg-air {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  width: 40%;
  max-width: 290px;
}

.sec02 .sec-body .inner {
  padding: 0;
}

.sec02 .sec-body ul#slick-blog li {
  padding: 0 10px;
}

.sec02 .sec-body ul#slick-blog li .li-in {
  overflow: hidden;
  position: relative;
}

.sec02 .sec-body ul#slick-blog li .li-in .li-img span {
  display: block;
  overflow: hidden;
  position: relative;
  line-height: 0;
  width: 100%;
  height: 0;
  padding-top: 70%;
}

.sec02 .sec-body ul#slick-blog li .li-in .li-img span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: transform .3s;
}

.sec02 .sec-body ul#slick-blog li .li-in .li-meta {
  margin: 10px 0 0;
}

.sec02 .sec-body ul#slick-blog li .li-in .li-meta .li-date {
  color: #7d7d7d;
}

.sec02 .sec-body ul#slick-blog li .li-in:hover .li-img span img {
  transform: translate(-50%, -50%) scale(1.2);
}

.sec03 .sec-body {
  letter-spacing: -.4em;
}

.sec03 .sec-body .sec-left,
.sec03 .sec-body .sec-right {
  letter-spacing: normal;
}

.sec03 .sec-body .sec-right {
  padding: 0 15px;
}

.sec03 .sec-body .sec-right .slogan,
.sec03 .sec-body .sec-right .idea {
  text-align: center;
}

.sec03 .sec-body .sec-right .slogan p,
.sec03 .sec-body .sec-right .idea p {
  line-height: 2.4;
}

.sec03 .sec-body .sec-right .slogan p span,
.sec03 .sec-body .sec-right .idea p span {
  display: inline-block;
}

.sec03 .sec-body .sec-right .slogan h3 {
  font-size: 18px;
  margin: 30px 0 20px;
}

.sec03 .sec-body .sec-right .slogan h3 span {
  display: block;
}

.sec03 .sec-body .sec-right .slogan p strong {
  font-size: 18px;
}

.sec03 .sec-body .sec-right .idea {
  margin: 30px 0 0;
}

.sec03 .sec-body .sec-right .idea p strong {
  display: inline-block;
  font-size: 16px;
}

.sec03 .sec-body .sec-right .idea p strong b {
  font-size: 18px;
  font-weight: bold;
}

#shop {
  margin: 0 -10px;
}

#shop .grid-sizer,
#shop .item {
  width: 50%;
  padding: 5px;
}

#shop .item.wide {
  width: 100%;
}

#shop .item .item-in {
  line-height: 0;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 65%;
}

#shop .item .item-in img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 110%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: all 2s;
}

#shop .item.wide .item-in {
  padding-top: 49%;
}

#shop .item.txt {
  text-align: center;
}

#shop .item.txt .item-in {
  line-height: 2;
  height: auto;
  padding: 5% 0;
}

#shop .item.txt .item-in p span {
  display: inline-block;
}

#shop .item.port .item-in {
  padding-top: calc(130% + 9.5px);
}

.sec05 .sec-right {
  text-align: center;
}

.sec05 .sec-right h3 span {
  font-size: 16px;
}

.sec05 .sec-right h3 strong {
  display: inline-block;
  position: relative;
  color: #2966A2;
  font-size: 18px;
  padding: 15px 30px;
  margin: 10px 0;
}

.sec05 .sec-right h3 strong:before,
.sec05 .sec-right h3 strong:after {
  content: '';
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
}

.sec05 .sec-right h3 strong:before {
  top: 0;
  left: 0;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}

.sec05 .sec-right h3 strong:after {
  bottom: 0;
  right: 0;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
}

.sec05 .sec-left {
  margin: 50px 0 0;
}

.sec05 .sec-left ul {
  letter-spacing: -.4em;
}

.sec05 .sec-left ul li {
  letter-spacing: normal;
  margin: 0 0 30px;
}

.sec05 .sec-left ul li .li-in .li-head {
  width: 60%;
  margin: auto;
}

.sec05 .sec-left ul li .li-in .li-head .li-prof {
  text-align: right;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-style: italic;
  margin: 0 0 5px;
  transform: translateX(15px) rotate(15deg);
}

.sec05 .sec-left ul li .li-in .li-body {
  margin: 15px 0 0;
}

.sec05 .sec-left ul li .li-in .li-body h4 {
  text-align: center;
  line-height: 1.6;
}

.sec05 .sec-left ul li .li-in .li-body h4 strong {
  display: block;
  font-size: 18px;
}

.sec05 .sec-left ul li .li-in .li-body p {
  margin: 10px 0 0;
  color: #666;
  font-size: 13px;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

.sec05 .sec-foot {
  margin: 50px 0 0;
}

.sec06 .sec-body .menu-info {
  text-align: center;
  margin: 0 0 30px;
}

.sec06 .sec-body .menu-info strong {
  font-size: 16px;
}

.sec06 .sec-body .menu-info table {
  width: auto;
  margin: auto;
}

.sec06 .sec-body .menu-info table tr td {
  text-align: left;
  padding: 0 10px;
}

.sec06 .sec-body .menu-info table tr td:last-of-type {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  text-align: right;
}

.sec06 .sec-body .menu-info p {
  margin: 5px 0;
}

nav#menus-nav {
  margin: 0 0 40px;
}

nav#menus-nav ul {
  letter-spacing: -.4em;
  margin: 0 -1%;
}

nav#menus-nav ul li {
  letter-spacing: normal;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  width: 48%;
  margin: 1%;
  font-size: 13px;
  padding: 15px 5px;
  background-color: #eee;
  transition: all .3s;
}

nav#menus-nav ul li.selected,
nav#menus-nav ul li:hover {
  color: #fff;
  background-color: #2966A2;
}

#menus-body {
  overflow: hidden;
}

#menus-body ul {
  position: relative;
}

#menus-body ul li {
  float: left;
}

#menus-body ul li .menu-wrap {
  margin: 30px 0 0;
}

#menus-body ul li .menu-wrap .menu-cat {
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
}

#menus-body ul li .menu-wrap .menu-cat h3 {
  color: #2966A2;
  font-size: 18px;
}

#menus-body ul li .menu-wrap .menu-cat h3 small {
  margin-left: 5px;
  color: #7d7d7d;
  font-size: 14px;
  font-style: italic;
}

#menus-body ul li .menu-wrap .menu-cat h3 small:before {
  content: '－ ';
}

#menus-body ul li .menu-wrap .menu-cat h3:before {
  content: '◆ ';
}

#menus-body ul li .menu-wrap .menu-dtl table {
  width: 100%;
}

#menus-body ul li .menu-wrap .menu-dtl table tr th,
#menus-body ul li .menu-wrap .menu-dtl table tr td {
  padding: 10px 2.5%;
}

#menus-body ul li .menu-wrap .menu-dtl table tr th {
  text-align: left;
  background-color: rgba(0, 0, 0, 0.1);
}

#menus-body ul li .menu-wrap .menu-dtl table tr th:before {
  content: '■ ';
}

#menus-body ul li .menu-wrap .menu-dtl table tr th + td {
  background-color: rgba(0, 0, 0, 0.1);
}

#menus-body ul li .menu-wrap .menu-dtl table tr td {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
}

#menus-body ul li .menu-wrap .menu-dtl table tr td span {
  display: inline-block;
}

#menus-body ul li .menu-wrap .menu-dtl table tr td:last-of-type {
  min-width: 100px;
  text-align: right;
}

#menus-body ul li .menu-wrap .menu-dtl table tr:last-of-type th,
#menus-body ul li .menu-wrap .menu-dtl table tr:last-of-type td {
  border: 0;
}

#menus-body ul li .menu-wrap .menu-dtl table:not(:first-of-type),
#menus-body ul li .menu-wrap .menu-dtl table.top-mgn {
  margin: 20px 0 0;
}

#menus-body ul li .notes {
  text-align: center;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 5%;
  border-radius: 10px;
}

#menus-body ul li .notes h4 {
  color: #aa003e;
  font-size: 16px;
  margin: 0 0 10px;
}

#menus-body ul li .notes .notes-dtl p {
  margin: 0 0 10px;
}

#menus-body ul li .notes .notes-dtl table {
  width: auto;
  margin: auto;
}

#menus-body ul li .notes .notes-dtl table tr td {
  padding: 5px 10px;
}

#menus-body ul li .notes .notes-dtl table tr td:last-of-type {
  text-align: right;
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

#menus-body ul li:first-of-type {
  background: url(../img/top/sec06/bg01.jpg) no-repeat center;
  background-size: cover;
}

#menus-body ul li:nth-of-type(2) {
  background: url(../img/top/sec06/bg02.jpg) no-repeat center;
  background-size: cover;
}

#menus-body ul li:nth-of-type(3) {
  background: url(../img/top/sec06/bg03.jpg) no-repeat center;
  background-size: cover;
}

#menus-body ul li:nth-of-type(4) {
  background: url(../img/top/sec06/bg04.jpg) no-repeat center;
  background-size: cover;
}

.sec07 {
  color: #fff;
  background-attachment: fixed;
  background-image: url(../img/top/sec07/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.sec07 .sec-body .job {
  margin: 20px 0 0;
}

.sec07 .sec-body .job .job-head {
  font-size: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.sec07 .sec-body .job .job-head small {
  font-size: 14px;
}

.sec07 .sec-body .job .job-body {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  line-height: 1.8;
}

.sec07 .sec-body .job .job-body table tr {
  display: block;
  margin: 15px 0 0;
}

.sec07 .sec-body .job .job-body table tr th,
.sec07 .sec-body .job .job-body table tr td {
  display: block;
  text-align: left;
}

.sec07 .sec-body .job .job-body table tr td {
  margin: 0 0 0 1.4em;
}

.sec07 .sec-body .job:first-of-type {
  margin: 0;
}

.sec07 .sec-foot {
  margin: 50px 0 0;
}

.sec07 .sec-foot .sec-foot-in {
  color: #2966A2;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 5%;
}

.sec07 .sec-foot .sec-foot-in h3 {
  text-align: center;
  font-size: 16px;
}

.sec07 .sec-foot .sec-foot-in h3 span {
  display: inline-block;
}

.sec07 .sec-foot .sec-foot-in table tr {
  display: block;
  margin: 15px 0 0;
}

.sec07 .sec-foot .sec-foot-in table tr th,
.sec07 .sec-foot .sec-foot-in table tr td {
  display: block;
  text-align: left;
}

.sec07 .sec-foot .sec-foot-in table tr td {
  margin: 0 0 0 1.4em;
}

.sec07 .sec-foot .sec-foot-in table tr td a {
  display: inline-block;
  font-size: 18px;
}

.sec07 .sec-foot .sec-foot-in table tr td span {
  display: inline-block;
}

.sec07:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(41, 102, 162, 0.8), #2966a2 100%);
}

.sec08 {
  background-color: #eee;
}

.sec08 .sec-body .nikai {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 5%;
  background: url(../img/top/sec08/bg.jpg) no-repeat center;
  background-size: cover;
}

.sec08 .sec-body .nikai .nikai-in {
  position: relative;
  z-index: 1;
}

.sec08 .sec-body .nikai .nikai-in .nikai-img {
  margin: 10px 0;
}

.sec08 .sec-body .nikai .nikai-in .nikai-img img {
  width: 190px;
}

.sec08 .sec-body .nikai .nikai-in .nikai-txt {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
}

.sec08 .sec-body .nikai a.permalink {
  z-index: 2;
}

.sec08 .sec-body .nikai:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transition: background-color .5s;
}

.sec08 .sec-body .nikai:hover .nikai-in .nikai-img img {
  animation: rotateLoop 1.5s ease-in-out 0s infinite;
}

.sec08 .sec-body .nikai:hover:before {
  background-color: rgba(0, 0, 0, 0.4);
}

@keyframes rotateLoop {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.sec09 .sec-body {
  font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  line-height: 1.8;
}

.sec09 .sec-body table {
  margin: 20px 0 0;
}

.sec09 .sec-body table tr {
  display: block;
  margin: 15px 0 0;
}

.sec09 .sec-body table tr th,
.sec09 .sec-body table tr td {
  display: block;
  text-align: left;
}

.sec09 .sec-body table tr td {
  margin: 0 0 0 1.4em;
}

ul#slick-app li {
  padding: 0 5px;
}

ul#slick-app li img {
  width: auto;
  height: 200px;
}

/* google map */
#gmap {
  position: relative;
  padding-bottom: 80%;
  height: 0;
  overflow: hidden;
}

#gmap-in {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

#_adjuvant .sec-head h2 {
  font-size: 20px;
}

#_adjuvant .sec-head p {
  margin: 0;
}

#_adjuvant .sec-head p.lead {
  color: #2966A2;
  font-size: 15px;
  font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", serif;
}

#_adjuvant .sec-head p.lead span {
  display: inline-block;
}

#_adjuvant .sec-body {
  letter-spacing: -.4em;
}

#_adjuvant .sec-body .sec-left,
#_adjuvant .sec-body .sec-right {
  letter-spacing: normal;
}

#_adjuvant .sec-body .sec-txt {
  padding: 5% 15px;
}

#_adjuvant .sec-body .sec-txt ul li,
#_adjuvant .sec-body .sec-txt ol li {
  position: relative;
  padding: 3px 0 3px 1.2em;
}

#_adjuvant .sec-body .sec-txt ol li span {
  position: absolute;
  top: 3px;
  left: 0;
}

#_adjuvant .sec-body .sec-txt ul li:before {
  content: '';
  display: block;
  position: absolute;
  top: 11px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 50%;
}

@media screen and (min-width: 600px) {
  #slides-wrap {
    padding: 15px;
  }
  .sec {
    padding: 10% 0 0;
  }
  .sec .sec-head {
    padding: 0 0 40px;
  }
  .sec.below {
    padding: 10% 0;
  }
  .sec03 .sec-body .sec-right .slogan h3 {
    margin: 50px 0 40px;
  }
  .sec03 .sec-body .sec-right .slogan h3 span {
    display: inline-block;
  }
  #shop .grid-sizer,
  #shop .item {
    width: 33.33%;
  }
  #shop .item.txt .item-in p {
    font-size: 16px;
  }
  .sec05 .sec-right h3 span {
    font-size: 18px;
  }
  .sec05 .sec-right h3 strong {
    font-size: 20px;
  }
  .sec05 .sec-left ul li {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
  .sec05 .sec-left ul li .li-in {
    padding: 0 15px;
  }
  nav#menus-nav ul li {
    font-size: 14px;
  }
  #menus-body ul li .menu-wrap .menu-cat h3 {
    font-size: 20px;
  }
  #menus-body ul li .menu-wrap .menu-dtl table tr th {
    font-size: 16px;
  }
  #menus-body ul li .notes h4 {
    font-size: 18px;
  }
  .sec07 .sec-body .job .job-head {
    font-size: 26px;
  }
  .sec07 .sec-body .job .job-body {
    margin: 10px 0 0;
  }
  .sec07 .sec-body .job .job-body table tr {
    display: table-row;
    margin: 0;
  }
  .sec07 .sec-body .job .job-body table tr th,
  .sec07 .sec-body .job .job-body table tr td {
    display: table-cell;
    margin: 0;
  }
  .sec07 .sec-body .job .job-body table tr th {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }
  .sec07 .sec-body .job .job-body table tr td {
    padding: 5px 0 5px 5px;
  }
  .sec07 .sec-foot .sec-foot-in h3 {
    font-size: 18px;
  }
  .sec07 .sec-foot .sec-foot-in table {
    max-width: 600px;
    margin: 15px auto 0;
  }
  .sec07 .sec-foot .sec-foot-in table tr {
    display: table-row;
    margin: 0;
  }
  .sec07 .sec-foot .sec-foot-in table tr th,
  .sec07 .sec-foot .sec-foot-in table tr td {
    display: table-cell;
    margin: 0;
  }
  .sec07 .sec-foot .sec-foot-in table tr th {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }
  .sec07 .sec-foot .sec-foot-in table tr td {
    padding: 5px 0 5px 5px;
  }
  .sec07 .sec-foot .sec-foot-in table tr td a {
    font-size: 20px;
  }
  .sec08 .sec-body .nikai .nikai-in .nikai-txt {
    font-size: 18px;
  }
  #gmap {
    padding-bottom: 40%;
  }
  #_adjuvant .sec-head h2 {
    font-size: 22px;
  }
  #_adjuvant .sec-head p.lead {
    font-size: 16px;
  }
}

@media screen and (min-width: 800px) {
  #slides-wrap .catch {
    width: 30%;
  }
  .sec {
    padding: 8% 0 0;
  }
  .sec .sec-head {
    padding: 0 0 50px;
  }
  .sec.below {
    padding: 8% 0;
  }
  .sec01 .sec-body .posts .post .post-in {
    padding-left: 30px;
  }
  .sec01 .sec-body .posts .post .post-in:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .sec01 .sec-body .posts .post .post-in:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 0;
    background-color: #000;
    transition: height .3s;
  }
  .sec01 .sec-body .posts .post .post-in:hover:after {
    height: 100%;
  }
  .sec02 .sec-body .inner {
    padding: 0 10px;
  }
  .sec02 .sec-body ul#slick-blog li {
    padding: 0 15px;
  }
  .sec03 .sec-body .sec-left,
  .sec03 .sec-body .sec-right {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
  .sec03 .sec-body .sec-right {
    margin: 0;
    padding: 0 15px 0 30px;
  }
  .sec03 .sec-body .sec-right .slogan,
  .sec03 .sec-body .sec-right .idea {
    text-align: left;
  }
  .sec03 .sec-body .sec-right .slogan h3 {
    margin: 0;
  }
  .sec03 .sec-body .sec-right .idea {
    margin: 20px 0 0;
  }
  #shop .grid-sizer,
  #shop .item {
    padding: 7.5px;
  }
  #shop .item.port .item-in {
    padding-top: calc(130% + 14.5px);
  }
  .sec05 .sec-right {
    float: right;
    width: 200px;
  }
  .sec05 .sec-right h3 span {
    writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    letter-spacing: 5px;
  }
  .sec05 .sec-left {
    margin: 0 200px 0 0;
  }
  .sec05 .sec-left ul li {
    width: 33.3%;
  }
  .sec05 .sec-left ul li .li-in .li-head {
    width: 100%;
  }
  .sec05 .sec-left ul li .li-in .li-body h4 {
    text-align: left;
  }
  .sec06 .sec-body .menu-info {
    margin: 0 0 50px;
  }
  .sec06 .sec-body .menu-info strong {
    font-size: 18px;
  }
  nav#menus-nav {
    margin: 0;
    border-bottom: 3px solid #2966A2;
  }
  nav#menus-nav ul {
    margin: 0 -0.5%;
    text-align: center;
  }
  nav#menus-nav ul li {
    vertical-align: bottom;
    width: 24%;
    margin: 0 0.5%;
    border-bottom: 0;
  }
  nav#menus-nav ul li.selected,
  nav#menus-nav ul li:hover {
    padding: 20px 5px;
  }
  .sec07 .sec-body .job {
    overflow: hidden;
    margin: 50px 0 0;
  }
  .sec07 .sec-body .job .job-head {
    float: left;
    width: 60px;
    border: 0;
    font-size: 28px;
  }
  .sec07 .sec-body .job .job-body {
    margin: 0 0 0 80px;
    padding: 0 0 0 20px;
    border-left: 1px solid rgba(255, 255, 255, 0.3);
  }
  .sec07 .sec-body .job .job-body table tr {
    display: table-row;
    margin: 0;
  }
  .sec07 .sec-body .job .job-body table tr th,
  .sec07 .sec-body .job .job-body table tr td {
    display: table-cell;
    margin: 0;
  }
  .sec07 .sec-body .job .job-body table tr th {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }
  .sec07 .sec-body .job .job-body table tr td {
    padding: 5px 0 5px 5px;
  }
  .sec07 .sec-foot .sec-foot-in h3 {
    font-size: 20px;
  }
  .sec07 .sec-foot .sec-foot-in table tr td a {
    font-size: 22px;
  }
  .sec08 .sec-body .nikai .nikai-in {
    width: 90%;
    margin: auto;
  }
  .sec08 .sec-body .nikai .nikai-in .nikai-img,
  .sec08 .sec-body .nikai .nikai-in .nikai-txt {
    display: inline-block;
    vertical-align: middle;
  }
  .sec08 .sec-body .nikai .nikai-in .nikai-img {
    width: 190px;
  }
  .sec08 .sec-body .nikai .nikai-in .nikai-txt {
    width: calc(100% - 190px);
    text-align: left;
    font-size: 20px;
    padding-left: 30px;
  }
  .sec09 .sec-body {
    overflow: hidden;
    position: relative;
    font-family: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
    line-height: 1.8;
  }
  .sec09 .sec-body table {
    width: 47%;
    float: right;
    margin: 20px 0;
  }
  .sec09 .sec-body table tr {
    display: table-row;
    margin: 0;
  }
  .sec09 .sec-body table tr th,
  .sec09 .sec-body table tr td {
    display: table-cell;
    margin: 0;
  }
  .sec09 .sec-body table tr th {
    white-space: nowrap;
    padding: 5px 5px 5px 0;
  }
  .sec09 .sec-body table tr td {
    padding: 5px 0 5px 5px;
  }
  ul#slick-app {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: auto;
  }
  ul#slick-app li img {
    height: 260px;
  }
  #_adjuvant .sec-head h2 {
    font-size: 24px;
  }
  #_adjuvant .sec-head p.lead {
    font-size: 17px;
  }
  #_adjuvant .sec-body {
    position: relative;
  }
  #_adjuvant .sec-body .sec-txt {
    font-size: 15px;
  }
  #_adjuvant .sec-body.sb01 {
    text-align: right;
  }
  #_adjuvant .sec-body.sb01 .sec-left {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }
  #_adjuvant .sec-body.sb01 .sec-left span img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 110%;
    transform: translate(-50%, -50%);
  }
  #_adjuvant .sec-body.sb01 .sec-right {
    display: inline-block;
    text-align: left;
    width: 50%;
    padding: 5%;
  }
  #_adjuvant .sec-body.sb02 .sec-right {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }
  #_adjuvant .sec-body.sb02 .sec-right span img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 110%;
    transform: translate(-50%, -50%);
  }
  #_adjuvant .sec-body.sb02 .sec-left {
    display: inline-block;
    text-align: left;
    width: 50%;
    padding: 5%;
  }
  #_adjuvant .sec-body.sb02 .sec-left ul li {
    padding: 5px 0 5px 1.2em;
  }
  #_adjuvant .sec-body.sb02 .sec-left ul li:before {
    top: 14px;
  }
}

@media screen and (min-width: 1000px) {
  .sec {
    padding: 6% 0 0;
  }
  .sec.below {
    padding: 6% 0;
  }
  .sec03 .sec-body .sec-right {
    padding: 0 15px 0 40px;
  }
  .sec03 .sec-body .sec-right .slogan h3 {
    font-size: 20px;
  }
  #shop .grid-sizer,
  #shop .item {
    width: 25%;
  }
  #shop .item.wide {
    width: 50%;
  }
  #shop .item.txt {
    text-align: right;
  }
  #shop .item.txt .item-in p {
    padding-right: 15px;
  }
  .sec05 .sec-right {
    width: 240px;
  }
  .sec05 .sec-right h3 span {
    font-size: 20px;
  }
  .sec05 .sec-right h3 strong {
    font-size: 22px;
  }
  .sec05 .sec-left {
    margin: 0 240px 0 0;
  }
  nav#menus-nav ul li {
    font-size: 15px;
  }
  #menus-body ul li .menu-wrap:first-of-type {
    margin: 50px 0 0;
  }
  .sec08 .sec-body .nikai .nikai-in .nikai-txt {
    font-size: 22px;
    padding-left: 40px;
  }
  #_adjuvant .sec-head h2 {
    font-size: 26px;
  }
  #_adjuvant .sec-head p.lead {
    font-size: 18px;
  }
}

@media screen and (min-width: 1200px) {
  .sec {
    padding: 100px 0 0;
  }
  .sec.below {
    padding: 100px 0;
  }
  .sec01 .bg-air {
    right: auto;
    left: 50%;
    transform: translateX(100%);
  }
  .sec02 .sec-body .inner {
    padding: 0;
  }
  .sec02 .sec-body ul#slick-blog {
    margin: 0 -25px;
  }
  .sec02 .sec-body ul#slick-blog li {
    padding: 0 25px;
  }
  .sec03 .sec-body .sec-right {
    padding: 0 15px 0 50px;
  }
  .sec03 .sec-body .sec-right .slogan h3 {
    font-size: 22px;
  }
  #shop .grid-sizer,
  #shop .item {
    width: 16.66%;
    padding: 10px;
  }
  #shop .item.port .item-in {
    padding-top: calc(130% + 19.5px);
  }
  .sec05 .sec-right {
    width: 260px;
  }
  .sec05 .sec-right h3 span {
    font-size: 22px;
  }
  .sec05 .sec-right h3 strong {
    font-size: 24px;
  }
  .sec05 .sec-left {
    margin: 0 260px 0 0;
  }
  .sec05 .sec-left ul li {
    width: 25%;
  }
  #_adjuvant .sec-head h2 {
    font-size: 28px;
  }
  #_adjuvant .sec-head p.lead {
    font-size: 20px;
  }
}

@keyframes DRAWING {
  0% {
    stroke-dashoffset: 3000;
    stroke: rgba(0, 0, 0, 0.8);
  }
  50% {
    stroke-dashoffset: 0;
    stroke-width: 1;
    stroke: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    stroke: transparent;
  }
}

@keyframes IMAGE_SHOWING {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.shake0 {
  animation: shake 2s ease 0s infinite normal;
  -webkit-animation: shake 2s ease 0s infinite normal;
}

.move0 {
  animation: move 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite normal;
  -webkit-animation: move 5s cubic-bezier(0.47, 0, 0.745, 0.715) 0s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos0 {
  left: 0%;
}

.scale0 {
  transform: scale(0);
  -webkit-transform: scale(0);
}

.shake1 {
  animation: shake 2.2s ease 0s infinite normal;
  -webkit-animation: shake 2.2s ease 0s infinite normal;
}

.move1 {
  animation: move 5.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s infinite normal;
  -webkit-animation: move 5.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos1 {
  left: 2%;
}

.scale1 {
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
}

.shake2 {
  animation: shake 2.4s ease 0s infinite normal;
  -webkit-animation: shake 2.4s ease 0s infinite normal;
}

.move2 {
  animation: move 5.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s infinite normal;
  -webkit-animation: move 5.4s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos2 {
  left: 4%;
}

.scale2 {
  transform: scale(0.2);
  -webkit-transform: scale(0.2);
}

.shake3 {
  animation: shake 2.6s ease 0s infinite normal;
  -webkit-animation: shake 2.6s ease 0s infinite normal;
}

.move3 {
  animation: move 5.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s infinite normal;
  -webkit-animation: move 5.6s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos3 {
  left: 6%;
}

.scale3 {
  transform: scale(0.3);
  -webkit-transform: scale(0.3);
}

.shake4 {
  animation: shake 2.8s ease 0s infinite normal;
  -webkit-animation: shake 2.8s ease 0s infinite normal;
}

.move4 {
  animation: move 5.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s infinite normal;
  -webkit-animation: move 5.8s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos4 {
  left: 8%;
}

.scale4 {
  transform: scale(0.4);
  -webkit-transform: scale(0.4);
}

.shake5 {
  animation: shake 3s ease 0s infinite normal;
  -webkit-animation: shake 3s ease 0s infinite normal;
}

.move5 {
  animation: move 6s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite normal;
  -webkit-animation: move 6s cubic-bezier(0.47, 0, 0.745, 0.715) 1s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos5 {
  left: 10%;
}

.scale5 {
  transform: scale(0.5);
  -webkit-transform: scale(0.5);
}

.shake6 {
  animation: shake 3.2s ease 0s infinite normal;
  -webkit-animation: shake 3.2s ease 0s infinite normal;
}

.move6 {
  animation: move 6.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s infinite normal;
  -webkit-animation: move 6.2s cubic-bezier(0.47, 0, 0.745, 0.715) 1.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos6 {
  left: 12%;
}

.scale6 {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
}

.shake7 {
  animation: shake 3.4s ease 0s infinite normal;
  -webkit-animation: shake 3.4s ease 0s infinite normal;
}

.move7 {
  animation: move 6.4s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s infinite normal;
  -webkit-animation: move 6.4s cubic-bezier(0.47, 0, 0.745, 0.715) 1.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos7 {
  left: 14%;
}

.scale7 {
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
}

.shake8 {
  animation: shake 3.6s ease 0s infinite normal;
  -webkit-animation: shake 3.6s ease 0s infinite normal;
}

.move8 {
  animation: move 6.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s infinite normal;
  -webkit-animation: move 6.6s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos8 {
  left: 16%;
}

.scale8 {
  transform: scale(0.8);
  -webkit-transform: scale(0.8);
}

.shake9 {
  animation: shake 3.8s ease 0s infinite normal;
  -webkit-animation: shake 3.8s ease 0s infinite normal;
}

.move9 {
  animation: move 6.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s infinite normal;
  -webkit-animation: move 6.8s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos9 {
  left: 18%;
}

.scale9 {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
}

.shake10 {
  animation: shake 4s ease 0s infinite normal;
  -webkit-animation: shake 4s ease 0s infinite normal;
}

.move10 {
  animation: move 7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s infinite normal;
  -webkit-animation: move 7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos10 {
  left: 20%;
}

.scale10 {
  transform: scale(1);
  -webkit-transform: scale(1);
}

.shake11 {
  animation: shake 4.2s ease 0s infinite normal;
  -webkit-animation: shake 4.2s ease 0s infinite normal;
}

.move11 {
  animation: move 7.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s infinite normal;
  -webkit-animation: move 7.2s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos11 {
  left: 22%;
}

.scale11 {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.shake12 {
  animation: shake 4.4s ease 0s infinite normal;
  -webkit-animation: shake 4.4s ease 0s infinite normal;
}

.move12 {
  animation: move 7.4s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s infinite normal;
  -webkit-animation: move 7.4s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos12 {
  left: 24%;
}

.scale12 {
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
}

.shake13 {
  animation: shake 4.6s ease 0s infinite normal;
  -webkit-animation: shake 4.6s ease 0s infinite normal;
}

.move13 {
  animation: move 7.6s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s infinite normal;
  -webkit-animation: move 7.6s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos13 {
  left: 26%;
}

.scale13 {
  transform: scale(1.3);
  -webkit-transform: scale(1.3);
}

.shake14 {
  animation: shake 4.8s ease 0s infinite normal;
  -webkit-animation: shake 4.8s ease 0s infinite normal;
}

.move14 {
  animation: move 7.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s infinite normal;
  -webkit-animation: move 7.8s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos14 {
  left: 28%;
}

.scale14 {
  transform: scale(1.4);
  -webkit-transform: scale(1.4);
}

.shake15 {
  animation: shake 5s ease 0s infinite normal;
  -webkit-animation: shake 5s ease 0s infinite normal;
}

.move15 {
  animation: move 8s cubic-bezier(0.47, 0, 0.745, 0.715) 3s infinite normal;
  -webkit-animation: move 8s cubic-bezier(0.47, 0, 0.745, 0.715) 3s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos15 {
  left: 30%;
}

.scale15 {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}

.shake16 {
  animation: shake 5.2s ease 0s infinite normal;
  -webkit-animation: shake 5.2s ease 0s infinite normal;
}

.move16 {
  animation: move 8.2s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s infinite normal;
  -webkit-animation: move 8.2s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos16 {
  left: 32%;
}

.scale16 {
  transform: scale(1.6);
  -webkit-transform: scale(1.6);
}

.shake17 {
  animation: shake 5.4s ease 0s infinite normal;
  -webkit-animation: shake 5.4s ease 0s infinite normal;
}

.move17 {
  animation: move 8.4s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4s infinite normal;
  -webkit-animation: move 8.4s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos17 {
  left: 34%;
}

.scale17 {
  transform: scale(1.7);
  -webkit-transform: scale(1.7);
}

.shake18 {
  animation: shake 5.6s ease 0s infinite normal;
  -webkit-animation: shake 5.6s ease 0s infinite normal;
}

.move18 {
  animation: move 8.6s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s infinite normal;
  -webkit-animation: move 8.6s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos18 {
  left: 36%;
}

.scale18 {
  transform: scale(1.8);
  -webkit-transform: scale(1.8);
}

.shake19 {
  animation: shake 5.8s ease 0s infinite normal;
  -webkit-animation: shake 5.8s ease 0s infinite normal;
}

.move19 {
  animation: move 8.8s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s infinite normal;
  -webkit-animation: move 8.8s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos19 {
  left: 38%;
}

.scale19 {
  transform: scale(1.9);
  -webkit-transform: scale(1.9);
}

.shake20 {
  animation: shake 6s ease 0s infinite normal;
  -webkit-animation: shake 6s ease 0s infinite normal;
}

.move20 {
  animation: move 9s cubic-bezier(0.47, 0, 0.745, 0.715) 4s infinite normal;
  -webkit-animation: move 9s cubic-bezier(0.47, 0, 0.745, 0.715) 4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos20 {
  left: 40%;
}

.scale20 {
  transform: scale(2);
  -webkit-transform: scale(2);
}

.shake21 {
  animation: shake 6.2s ease 0s infinite normal;
  -webkit-animation: shake 6.2s ease 0s infinite normal;
}

.move21 {
  animation: move 9.2s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s infinite normal;
  -webkit-animation: move 9.2s cubic-bezier(0.47, 0, 0.745, 0.715) 4.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos21 {
  left: 42%;
}

.scale21 {
  transform: scale(2.1);
  -webkit-transform: scale(2.1);
}

.shake22 {
  animation: shake 6.4s ease 0s infinite normal;
  -webkit-animation: shake 6.4s ease 0s infinite normal;
}

.move22 {
  animation: move 9.4s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4s infinite normal;
  -webkit-animation: move 9.4s cubic-bezier(0.47, 0, 0.745, 0.715) 4.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos22 {
  left: 44%;
}

.scale22 {
  transform: scale(2.2);
  -webkit-transform: scale(2.2);
}

.shake23 {
  animation: shake 6.6s ease 0s infinite normal;
  -webkit-animation: shake 6.6s ease 0s infinite normal;
}

.move23 {
  animation: move 9.6s cubic-bezier(0.47, 0, 0.745, 0.715) 4.6s infinite normal;
  -webkit-animation: move 9.6s cubic-bezier(0.47, 0, 0.745, 0.715) 4.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos23 {
  left: 46%;
}

.scale23 {
  transform: scale(2.3);
  -webkit-transform: scale(2.3);
}

.shake24 {
  animation: shake 6.8s ease 0s infinite normal;
  -webkit-animation: shake 6.8s ease 0s infinite normal;
}

.move24 {
  animation: move 9.8s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s infinite normal;
  -webkit-animation: move 9.8s cubic-bezier(0.47, 0, 0.745, 0.715) 4.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos24 {
  left: 48%;
}

.scale24 {
  transform: scale(2.4);
  -webkit-transform: scale(2.4);
}

.shake25 {
  animation: shake 7s ease 0s infinite normal;
  -webkit-animation: shake 7s ease 0s infinite normal;
}

.move25 {
  animation: move 10s cubic-bezier(0.47, 0, 0.745, 0.715) 5s infinite normal;
  -webkit-animation: move 10s cubic-bezier(0.47, 0, 0.745, 0.715) 5s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos25 {
  left: 50%;
}

.scale25 {
  transform: scale(2.5);
  -webkit-transform: scale(2.5);
}

.shake26 {
  animation: shake 7.2s ease 0s infinite normal;
  -webkit-animation: shake 7.2s ease 0s infinite normal;
}

.move26 {
  animation: move 10.2s cubic-bezier(0.47, 0, 0.745, 0.715) 5.2s infinite normal;
  -webkit-animation: move 10.2s cubic-bezier(0.47, 0, 0.745, 0.715) 5.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos26 {
  left: 52%;
}

.scale26 {
  transform: scale(2.6);
  -webkit-transform: scale(2.6);
}

.shake27 {
  animation: shake 7.4s ease 0s infinite normal;
  -webkit-animation: shake 7.4s ease 0s infinite normal;
}

.move27 {
  animation: move 10.4s cubic-bezier(0.47, 0, 0.745, 0.715) 5.4s infinite normal;
  -webkit-animation: move 10.4s cubic-bezier(0.47, 0, 0.745, 0.715) 5.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos27 {
  left: 54%;
}

.scale27 {
  transform: scale(2.7);
  -webkit-transform: scale(2.7);
}

.shake28 {
  animation: shake 7.6s ease 0s infinite normal;
  -webkit-animation: shake 7.6s ease 0s infinite normal;
}

.move28 {
  animation: move 10.6s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6s infinite normal;
  -webkit-animation: move 10.6s cubic-bezier(0.47, 0, 0.745, 0.715) 5.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos28 {
  left: 56%;
}

.scale28 {
  transform: scale(2.8);
  -webkit-transform: scale(2.8);
}

.shake29 {
  animation: shake 7.8s ease 0s infinite normal;
  -webkit-animation: shake 7.8s ease 0s infinite normal;
}

.move29 {
  animation: move 10.8s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8s infinite normal;
  -webkit-animation: move 10.8s cubic-bezier(0.47, 0, 0.745, 0.715) 5.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos29 {
  left: 58%;
}

.scale29 {
  transform: scale(2.9);
  -webkit-transform: scale(2.9);
}

.shake30 {
  animation: shake 8s ease 0s infinite normal;
  -webkit-animation: shake 8s ease 0s infinite normal;
}

.move30 {
  animation: move 11s cubic-bezier(0.47, 0, 0.745, 0.715) 6s infinite normal;
  -webkit-animation: move 11s cubic-bezier(0.47, 0, 0.745, 0.715) 6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos30 {
  left: 60%;
}

.scale30 {
  transform: scale(3);
  -webkit-transform: scale(3);
}

.shake31 {
  animation: shake 8.2s ease 0s infinite normal;
  -webkit-animation: shake 8.2s ease 0s infinite normal;
}

.move31 {
  animation: move 11.2s cubic-bezier(0.47, 0, 0.745, 0.715) 6.2s infinite normal;
  -webkit-animation: move 11.2s cubic-bezier(0.47, 0, 0.745, 0.715) 6.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos31 {
  left: 62%;
}

.scale31 {
  transform: scale(3.1);
  -webkit-transform: scale(3.1);
}

.shake32 {
  animation: shake 8.4s ease 0s infinite normal;
  -webkit-animation: shake 8.4s ease 0s infinite normal;
}

.move32 {
  animation: move 11.4s cubic-bezier(0.47, 0, 0.745, 0.715) 6.4s infinite normal;
  -webkit-animation: move 11.4s cubic-bezier(0.47, 0, 0.745, 0.715) 6.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos32 {
  left: 64%;
}

.scale32 {
  transform: scale(3.2);
  -webkit-transform: scale(3.2);
}

.shake33 {
  animation: shake 8.6s ease 0s infinite normal;
  -webkit-animation: shake 8.6s ease 0s infinite normal;
}

.move33 {
  animation: move 11.6s cubic-bezier(0.47, 0, 0.745, 0.715) 6.6s infinite normal;
  -webkit-animation: move 11.6s cubic-bezier(0.47, 0, 0.745, 0.715) 6.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos33 {
  left: 66%;
}

.scale33 {
  transform: scale(3.3);
  -webkit-transform: scale(3.3);
}

.shake34 {
  animation: shake 8.8s ease 0s infinite normal;
  -webkit-animation: shake 8.8s ease 0s infinite normal;
}

.move34 {
  animation: move 11.8s cubic-bezier(0.47, 0, 0.745, 0.715) 6.8s infinite normal;
  -webkit-animation: move 11.8s cubic-bezier(0.47, 0, 0.745, 0.715) 6.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos34 {
  left: 68%;
}

.scale34 {
  transform: scale(3.4);
  -webkit-transform: scale(3.4);
}

.shake35 {
  animation: shake 9s ease 0s infinite normal;
  -webkit-animation: shake 9s ease 0s infinite normal;
}

.move35 {
  animation: move 12s cubic-bezier(0.47, 0, 0.745, 0.715) 7s infinite normal;
  -webkit-animation: move 12s cubic-bezier(0.47, 0, 0.745, 0.715) 7s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos35 {
  left: 70%;
}

.scale35 {
  transform: scale(3.5);
  -webkit-transform: scale(3.5);
}

.shake36 {
  animation: shake 9.2s ease 0s infinite normal;
  -webkit-animation: shake 9.2s ease 0s infinite normal;
}

.move36 {
  animation: move 12.2s cubic-bezier(0.47, 0, 0.745, 0.715) 7.2s infinite normal;
  -webkit-animation: move 12.2s cubic-bezier(0.47, 0, 0.745, 0.715) 7.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos36 {
  left: 72%;
}

.scale36 {
  transform: scale(3.6);
  -webkit-transform: scale(3.6);
}

.shake37 {
  animation: shake 9.4s ease 0s infinite normal;
  -webkit-animation: shake 9.4s ease 0s infinite normal;
}

.move37 {
  animation: move 12.4s cubic-bezier(0.47, 0, 0.745, 0.715) 7.4s infinite normal;
  -webkit-animation: move 12.4s cubic-bezier(0.47, 0, 0.745, 0.715) 7.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos37 {
  left: 74%;
}

.scale37 {
  transform: scale(3.7);
  -webkit-transform: scale(3.7);
}

.shake38 {
  animation: shake 9.6s ease 0s infinite normal;
  -webkit-animation: shake 9.6s ease 0s infinite normal;
}

.move38 {
  animation: move 12.6s cubic-bezier(0.47, 0, 0.745, 0.715) 7.6s infinite normal;
  -webkit-animation: move 12.6s cubic-bezier(0.47, 0, 0.745, 0.715) 7.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos38 {
  left: 76%;
}

.scale38 {
  transform: scale(3.8);
  -webkit-transform: scale(3.8);
}

.shake39 {
  animation: shake 9.8s ease 0s infinite normal;
  -webkit-animation: shake 9.8s ease 0s infinite normal;
}

.move39 {
  animation: move 12.8s cubic-bezier(0.47, 0, 0.745, 0.715) 7.8s infinite normal;
  -webkit-animation: move 12.8s cubic-bezier(0.47, 0, 0.745, 0.715) 7.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos39 {
  left: 78%;
}

.scale39 {
  transform: scale(3.9);
  -webkit-transform: scale(3.9);
}

.shake40 {
  animation: shake 10s ease 0s infinite normal;
  -webkit-animation: shake 10s ease 0s infinite normal;
}

.move40 {
  animation: move 13s cubic-bezier(0.47, 0, 0.745, 0.715) 8s infinite normal;
  -webkit-animation: move 13s cubic-bezier(0.47, 0, 0.745, 0.715) 8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos40 {
  left: 80%;
}

.scale40 {
  transform: scale(4);
  -webkit-transform: scale(4);
}

.shake41 {
  animation: shake 10.2s ease 0s infinite normal;
  -webkit-animation: shake 10.2s ease 0s infinite normal;
}

.move41 {
  animation: move 13.2s cubic-bezier(0.47, 0, 0.745, 0.715) 8.2s infinite normal;
  -webkit-animation: move 13.2s cubic-bezier(0.47, 0, 0.745, 0.715) 8.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos41 {
  left: 82%;
}

.scale41 {
  transform: scale(4.1);
  -webkit-transform: scale(4.1);
}

.shake42 {
  animation: shake 10.4s ease 0s infinite normal;
  -webkit-animation: shake 10.4s ease 0s infinite normal;
}

.move42 {
  animation: move 13.4s cubic-bezier(0.47, 0, 0.745, 0.715) 8.4s infinite normal;
  -webkit-animation: move 13.4s cubic-bezier(0.47, 0, 0.745, 0.715) 8.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos42 {
  left: 84%;
}

.scale42 {
  transform: scale(4.2);
  -webkit-transform: scale(4.2);
}

.shake43 {
  animation: shake 10.6s ease 0s infinite normal;
  -webkit-animation: shake 10.6s ease 0s infinite normal;
}

.move43 {
  animation: move 13.6s cubic-bezier(0.47, 0, 0.745, 0.715) 8.6s infinite normal;
  -webkit-animation: move 13.6s cubic-bezier(0.47, 0, 0.745, 0.715) 8.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos43 {
  left: 86%;
}

.scale43 {
  transform: scale(4.3);
  -webkit-transform: scale(4.3);
}

.shake44 {
  animation: shake 10.8s ease 0s infinite normal;
  -webkit-animation: shake 10.8s ease 0s infinite normal;
}

.move44 {
  animation: move 13.8s cubic-bezier(0.47, 0, 0.745, 0.715) 8.8s infinite normal;
  -webkit-animation: move 13.8s cubic-bezier(0.47, 0, 0.745, 0.715) 8.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos44 {
  left: 88%;
}

.scale44 {
  transform: scale(4.4);
  -webkit-transform: scale(4.4);
}

.shake45 {
  animation: shake 11s ease 0s infinite normal;
  -webkit-animation: shake 11s ease 0s infinite normal;
}

.move45 {
  animation: move 14s cubic-bezier(0.47, 0, 0.745, 0.715) 9s infinite normal;
  -webkit-animation: move 14s cubic-bezier(0.47, 0, 0.745, 0.715) 9s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos45 {
  left: 90%;
}

.scale45 {
  transform: scale(4.5);
  -webkit-transform: scale(4.5);
}

.shake46 {
  animation: shake 11.2s ease 0s infinite normal;
  -webkit-animation: shake 11.2s ease 0s infinite normal;
}

.move46 {
  animation: move 14.2s cubic-bezier(0.47, 0, 0.745, 0.715) 9.2s infinite normal;
  -webkit-animation: move 14.2s cubic-bezier(0.47, 0, 0.745, 0.715) 9.2s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos46 {
  left: 92%;
}

.scale46 {
  transform: scale(4.6);
  -webkit-transform: scale(4.6);
}

.shake47 {
  animation: shake 11.4s ease 0s infinite normal;
  -webkit-animation: shake 11.4s ease 0s infinite normal;
}

.move47 {
  animation: move 14.4s cubic-bezier(0.47, 0, 0.745, 0.715) 9.4s infinite normal;
  -webkit-animation: move 14.4s cubic-bezier(0.47, 0, 0.745, 0.715) 9.4s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos47 {
  left: 94%;
}

.scale47 {
  transform: scale(4.7);
  -webkit-transform: scale(4.7);
}

.shake48 {
  animation: shake 11.6s ease 0s infinite normal;
  -webkit-animation: shake 11.6s ease 0s infinite normal;
}

.move48 {
  animation: move 14.6s cubic-bezier(0.47, 0, 0.745, 0.715) 9.6s infinite normal;
  -webkit-animation: move 14.6s cubic-bezier(0.47, 0, 0.745, 0.715) 9.6s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos48 {
  left: 96%;
}

.scale48 {
  transform: scale(4.8);
  -webkit-transform: scale(4.8);
}

.shake49 {
  animation: shake 11.8s ease 0s infinite normal;
  -webkit-animation: shake 11.8s ease 0s infinite normal;
}

.move49 {
  animation: move 14.8s cubic-bezier(0.47, 0, 0.745, 0.715) 9.8s infinite normal;
  -webkit-animation: move 14.8s cubic-bezier(0.47, 0, 0.745, 0.715) 9.8s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos49 {
  left: 98%;
}

.scale49 {
  transform: scale(4.9);
  -webkit-transform: scale(4.9);
}

.shake50 {
  animation: shake 12s ease 0s infinite normal;
  -webkit-animation: shake 12s ease 0s infinite normal;
}

.move50 {
  animation: move 15s cubic-bezier(0.47, 0, 0.745, 0.715) 10s infinite normal;
  -webkit-animation: move 15s cubic-bezier(0.47, 0, 0.745, 0.715) 10s infinite normal;
  position: absolute;
  bottom: -10%;
}

.pos50 {
  left: 100%;
}

.scale50 {
  transform: scale(5);
  -webkit-transform: scale(5);
}

@keyframes shake {
  0% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes move {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-1500px);
    opacity: 0;
  }
}
